.home-page {
    padding: 0;
    max-width: 100%;
    margin: 0;

    .hero-section {
        margin-top: 0;
        padding-top: 0;
        width: 100vw;
        position: relative;
        left: 50%;
        right: 50%;
        margin-left: -50vw;
        margin-right: -50vw;
        background: #fff;
        
        .carousel-item {
            .carousel-content {
                height: 280px;
                padding-top: 64px;
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                position: relative;
                overflow: hidden;

                &::before {
                    content: '';
                    position: absolute;
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                    background: radial-gradient(circle at center, rgba(255,255,255,0.1) 0%, transparent 70%);
                    pointer-events: none;
                }
                
                .hero-content {
                    position: relative;
                    max-width: 1200px;
                    margin: 0 auto;
                    padding: 0 24px;
                    text-align: center;

                    h1 {
                        color: white;
                        font-size: 32px;
                        margin-bottom: 16px;
                        font-weight: 600;
                        text-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
                    }

                    .ant-typography {
                        color: rgba(255, 255, 255, 0.95);
                        font-size: 16px;
                        max-width: 700px;
                        margin: 0 auto 20px;
                        text-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
                    }

                    .hero-button {
                        height: 44px;
                        padding: 0 28px;
                        font-size: 16px;
                        border-radius: 22px;
                        background: rgba(255, 255, 255, 0.15);
                        border: 2px solid rgba(255, 255, 255, 0.8);
                        backdrop-filter: blur(4px);
                        transition: all 0.3s ease;

                        &:hover {
                            background: rgba(255, 255, 255, 0.25);
                            transform: translateY(-2px);
                            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
                        }
                    }
                }
            }
        }

        .ant-carousel {
            .slick-dots {
                bottom: 20px;

                li {
                    margin: 0 4px;

                    button {
                        background: rgba(255, 255, 255, 0.4);
                        height: 6px;
                        border-radius: 3px;
                        transition: all 0.3s ease;
                        opacity: 0.7;
                    }

                    &.slick-active button {
                        background: #fff;
                        width: 20px;
                        opacity: 1;
                    }

                    &:hover button {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .content-container {
        position: relative;
        max-width: 1200px;
        margin: 0 auto;
        padding: 0 16px;
    }

    section {
        margin-bottom: 48px;

        .ant-typography {
            display: flex;
            align-items: center;
            gap: 8px;
            margin-bottom: 24px;
            font-size: 20px;
            color: rgba(0, 0, 0, 0.85);
        }
    }

    .tool-card {
        height: 100%;
        display: flex;
        flex-direction: column;
        border-radius: 8px;
        transition: all 0.3s;
        border: 1px solid #f0f0f0;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
        }

        .tool-header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 16px;
            padding: 16px 16px 0;

            .tool-icon {
                height: 48px;
                width: 48px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: #f5f5f5;
                border-radius: 12px;
                font-size: 24px;
            }

            .tool-usage {
                font-size: 12px;
                color: #ff4d4f;
                display: flex;
                align-items: center;
                gap: 4px;
                background: rgba(255, 77, 79, 0.1);
                padding: 4px 8px;
                border-radius: 12px;

                .anticon {
                    font-size: 14px;
                }
            }
        }

        .ant-card-body {
            padding: 16px;

            .ant-card-meta {
                margin-bottom: 16px;

                .ant-card-meta-title {
                    font-size: 16px;
                    margin-bottom: 8px;
                    line-height: 1.4;
                }

                .ant-card-meta-description {
                    font-size: 12px;
                    color: rgba(0, 0, 0, 0.45);
                    line-height: 1.5;
                }
            }
        }

        .use-button {
            margin-top: auto;
            width: 100%;
            font-size: 13px;
            height: 32px;
            border-radius: 6px;
            background: #1677ff;
            
            &:hover {
                background: #4096ff;
            }
        }
    }

    .category-card {
        text-align: center;
        padding: 20px;
        border-radius: 8px;
        transition: all 0.3s;

        &:hover {
            transform: translateY(-2px);
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
        }

        .category-icon {
            font-size: 32px;
            margin-bottom: 12px;
        }

        h4.ant-typography {
            font-size: 16px;
            margin-bottom: 12px !important;
            display: block !important;
        }

        .tool-list {
            display: grid;
            grid-template-columns: 1fr 1fr;
            gap: 8px;

            .tool-item {
                font-size: 12px;
                color: rgba(0, 0, 0, 0.65);
                padding: 6px 8px;
                background: #f5f5f5;
                border-radius: 4px;
                cursor: pointer;
                transition: all 0.3s;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                line-height: 1.4;
                height: 28px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:hover {
                    color: #1677ff;
                    background: #e6f4ff;
                }
            }
        }
    }

    .recent-card {
        .ant-card-meta-title {
            font-size: 14px;
        }

        .ant-card-meta-description {
            font-size: 12px;
            color: rgba(0, 0, 0, 0.45);
        }
    }
} 