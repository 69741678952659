.contact-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;

    .page-header {
        background: linear-gradient(135deg, #f0f5ff 0%, #e6fffb 100%);
        padding: 32px;
        border-radius: 12px;
        margin-bottom: 32px;
        box-shadow: 0 2px 12px rgba(0, 0, 0, 0.03);

        h2.ant-typography {
            font-size: 24px;
            margin-bottom: 12px;
            background: linear-gradient(45deg, #1890ff, #36cfc9);
            -webkit-background-clip: text;
            color: transparent;
        }

        .intro {
            font-size: 14px;
            color: #666;
            margin-bottom: 0;
            max-width: 600px;
        }
    }

    .contact-content {
        display: grid;
        grid-template-columns: 1fr 2fr;
        gap: 24px;

        .contact-info {
            background: #fff;
            border-radius: 12px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-2px);
                box-shadow: 0 4px 16px rgba(0, 0, 0, 0.08);
            }

            .info-item {
                display: flex;
                align-items: flex-start;
                margin-bottom: 20px;
                padding: 16px;
                border-bottom: 1px solid #f0f0f0;
                transition: all 0.3s ease;

                &:last-child {
                    border-bottom: none;
                    margin-bottom: 0;
                }

                &:hover {
                    background: #fafafa;

                    .icon {
                        transform: scale(1.1);
                        color: #40a9ff;
                    }
                }

                .icon {
                    font-size: 20px;
                    color: #1890ff;
                    margin-right: 12px;
                    margin-top: 2px;
                    transition: all 0.3s ease;
                }

                .details {
                    h4 {
                        margin: 0 0 4px;
                        color: #1f1f1f;
                        font-size: 14px;
                        font-weight: 500;
                    }

                    p {
                        margin: 0;
                        color: #666;
                        font-size: 13px;
                    }
                }
            }
        }

        .contact-form {
            background: #fff;
            padding: 24px;
            border-radius: 12px;
            box-shadow: 0 2px 12px rgba(0, 0, 0, 0.05);

            h3.ant-typography {
                font-size: 18px;
                margin-bottom: 24px;
                color: #1f1f1f;
            }

            .ant-form-item-label {
                label {
                    color: #1f1f1f;
                    font-size: 14px;
                }
            }

            .ant-input, .ant-input-textarea {
                font-size: 13px;
                border-radius: 6px;
                border-color: #e8e8e8;
                transition: all 0.3s ease;

                &:hover, &:focus {
                    border-color: #40a9ff;
                    box-shadow: 0 0 0 2px rgba(24, 144, 255, 0.1);
                }
            }

            .ant-btn {
                font-size: 14px;
                height: 40px;
                border-radius: 6px;
                background: linear-gradient(45deg, #1890ff, #40a9ff);
                border: none;
                box-shadow: 0 2px 8px rgba(24, 144, 255, 0.35);
                transition: all 0.3s ease;

                &:hover {
                    transform: translateY(-1px);
                    box-shadow: 0 4px 12px rgba(24, 144, 255, 0.45);
                    background: linear-gradient(45deg, #40a9ff, #69c0ff);
                }
            }
        }
    }

    @media (max-width: 768px) {
        padding: 16px;

        .page-header {
            padding: 24px;
            margin-bottom: 24px;
        }

        .contact-content {
            grid-template-columns: 1fr;
            gap: 16px;

            .contact-info, .contact-form {
                padding: 16px;
            }
        }
    }
} 