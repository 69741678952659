.tool-detail-page {
    padding: 24px;
    padding-top: 24px;
    max-width: 1200px;
    margin: 0 auto;

    .breadcrumb {
        margin-bottom: 10px;

        .anticon {
            margin-right: 8px;
        }
    }

    .tool-header {
        margin-bottom: 24px;

        .back-button {
            margin-bottom: 16px;
            position: relative;
    top: 2px;
        }

        .title-wrapper {
            margin-bottom: 16px;

            .tool-icon {
                font-size: 32px;
                opacity: 0.9;
            }

            h2.ant-typography {
                margin: 0;
            }

            .hot-tag {
                background: linear-gradient(135deg, #ff4d4f 0%, #ff7875 100%);
                color: white;
                padding: 2px 12px;
                border-radius: 12px;
                font-size: 12px;
            }
        }

        .ant-typography {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
            max-width: 600px;
        }
    }

    .tool-content {
        min-height: 400px;
        background: #fff;
        border-radius: 12px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);

        .tool-placeholder {
            text-align: center;
            padding: 60px 20px;

            h3.ant-typography {
                margin-bottom: 16px;
                color: rgba(0, 0, 0, 0.85);
            }

            .ant-typography {
                color: rgba(0, 0, 0, 0.45);
            }
        }
    }

    &.error {
        text-align: center;
        padding-top: 120px;

        h2.ant-typography {
            margin-bottom: 24px;
        }
    }
} 