.categories-page {
    padding: 24px;
    padding-top: 88px;

    .search-section {
        text-align: center;
        margin-bottom: 48px;

        h2.ant-typography {
            font-size: 20px;
            margin-bottom: 12px;
        }

        .ant-typography {
            color: rgba(0, 0, 0, 0.65);
            font-size: 14px;
        }

        .search-input {
            max-width: 800px;
            margin: 24px auto 0;

            .ant-input-group-addon {
                display: none;
            }
            
            .ant-input-affix-wrapper,
            .ant-input-wrapper,
            .ant-input,
            .ant-input-search-button,
            .ant-input-suffix,
            .search-count {
                height: 48px;
                line-height: 48px;
            }
            
            .ant-input-affix-wrapper {
                padding: 0;
                border: none;
                box-shadow: none;
                border-radius: 0;
            }
            
            .ant-input-wrapper {
                border-radius: 24px;
                background: #fff;
                border: 1px solid #e5e7eb;
                box-shadow: 0 2px 8px rgba(0, 0, 0, 0.03);
                overflow: hidden;
                transition: all 0.3s ease;
                display: flex;
                align-items: center;
                
                .ant-input {
                    font-size: 14px;
                    padding: 0 24px;
                    border: none;
                    box-shadow: none;
                    background: transparent;
                    flex: 1;

                    &::placeholder {
                        color: rgba(0, 0, 0, 0.45);
                    }
                }

                .ant-input-group-addon {
                    padding: 0;
                    border: none;
                    background: none;

                    .ant-input-search-button {
                        min-width: 64px;
                        border: none;
                        background: #1677ff;
                        box-shadow: none;
                        border-radius: 0;
                        margin: 0;
                        padding: 0;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        height: 48px;

                        &:hover {
                            background: #4096ff;
                        }

                        .anticon {
                            color: #fff;
                            font-size: 18px;
                            line-height: 1;
                        }
                    }
                }
            }

            .ant-input-suffix {
                position: static;
                margin-right: 16px;
                display: flex;
                align-items: center;
                gap: 12px;

                .search-count {
                    font-size: 13px;
                    color: rgba(0, 0, 0, 0.45);
                    white-space: nowrap;
                    display: flex;
                    align-items: center;
                }

                .ant-input-clear-icon {
                    color: rgba(0, 0, 0, 0.25);
                    font-size: 14px;
                    cursor: pointer;
                    transition: color 0.2s;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 24px;
                    height: 24px;

                    &:hover {
                        color: rgba(0, 0, 0, 0.45);
                    }
                }
            }

            &:hover, &.ant-input-search-focused {
                .ant-input-wrapper {
                    border-color: #1677ff;
                    box-shadow: 0 4px 16px rgba(22, 119, 255, 0.1);
                }
            }
        }
    }

    .category-section {
        margin-bottom: 64px;

        .category-header {
            margin-bottom: 32px;
            position: relative;
            padding-left: 16px;

            &::before {
                content: '';
                position: absolute;
                left: 0;
                top: 8px;
                bottom: 8px;
                width: 4px;
                background: #1677ff;
                border-radius: 2px;
            }

            h3.ant-typography {
                display: flex;
                align-items: center;
                gap: 12px;
                margin-bottom: 8px;
                font-size: 20px;

                .category-icon {
                    font-size: 24px;
                }
            }

            .ant-typography {
                color: rgba(0, 0, 0, 0.65);
                margin-bottom: 0;
                font-size: 14px;
            }
        }

        .tool-card {
            height: 100%;
            display: flex;
            flex-direction: column;
            border-radius: 12px;
            transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
            border: 1px solid #f0f0f0;
            padding: 16px;
            background: linear-gradient(135deg, #ffffff 0%, #f8faff 100%);
            position: relative;
            overflow: hidden;

            // 内容区域容器
            .ant-card-body {
                flex: 1;
                display: flex;
                flex-direction: column;
                padding: 0;
                height: 100%;
                position: relative;
            }

            // 热门标签移到右上角
            .ant-tag {
                position: absolute;
                top: -4px;
                right: -4px;
                z-index: 2;
                border-radius: 0 10px 0 10px;
                font-size: 12px;
                padding: 2px 8px;
                border: none;
                background: linear-gradient(135deg, #ff4d4f 0%, #ff7875 100%);
                color: white;
                backdrop-filter: blur(4px);
                display: inline-flex;
                align-items: center;
                gap: 4px;
                margin: 0;
                min-width: auto;
                box-shadow: -2px 2px 6px rgba(255, 77, 79, 0.2);

                &::before {
                    content: '🔥';
                    font-size: 10px;
                }
            }

            h4.ant-typography {
                font-size: 15px;
                margin-bottom: 8px;
                color: rgba(0, 0, 0, 0.88);
                font-weight: 500;
                display: flex;
                align-items: center;
                gap: 8px;
                position: relative;
                z-index: 1;

                .tool-icon {
                    font-size: 16px;
                    opacity: 0.8;
                }
            }

            .ant-typography {
                color: rgba(0, 0, 0, 0.45);
                font-size: 12px;
                margin-bottom: 12px;
                line-height: 1.5;
                position: relative;
                z-index: 1;
            }

            // 底部按钮
            .card-footer {
                margin-top: auto;
                display: flex;
                flex-direction: column;
                align-items: center;
                gap: 12px;
            }

            .use-button {
                position: relative;
                z-index: 1;
                border-radius: 6px;
                height: 28px;
                font-size: 12px;
                font-weight: 500;
                transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                background: #1677ff;
                border: none;
                box-shadow: 0 2px 0 rgba(0, 0, 0, 0.02);
                display: inline-flex;
                align-items: center;
                justify-content: center;
                gap: 6px;
                width: auto;
                min-width: 88px;
                padding: 0 16px;

                &::after {
                    content: '→';
                    font-size: 14px;
                    transition: transform 0.3s cubic-bezier(0.4, 0, 0.2, 1);
                    margin-top: -1px;
                }

                &:hover {
                    transform: translateY(-1px);
                    background: #4096ff;
                    box-shadow: 0 4px 12px rgba(22, 119, 255, 0.25);

                    &::after {
                        transform: translateX(4px);
                    }
                }
            }

            // 悬浮效果
            &:hover {
                transform: translateY(-3px);
                box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
                border-color: rgba(22, 119, 255, 0.2);
                background: linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%);
            }

            // 根据分类添加不同的背景色调
            &[data-category="text"] {
                background: linear-gradient(135deg, #ffffff 0%, #f0f7ff 100%);
            }

            &[data-category="image"] {
                background: linear-gradient(135deg, #ffffff 0%, #f6fff0 100%);
            }

            &[data-category="dev"] {
                background: linear-gradient(135deg, #ffffff 0%, #fff7f0 100%);
            }

            &[data-category="format"] {
                background: linear-gradient(135deg, #ffffff 0%, #f0f9ff 100%);
            }

            &[data-category="encryption"] {
                background: linear-gradient(135deg, #ffffff 0%, #f7f0ff 100%);
            }

            &[data-category="calculator"] {
                background: linear-gradient(135deg, #ffffff 0%, #fff0f9 100%);
            }

            &[data-category="network"] {
                background: linear-gradient(135deg, #ffffff 0%, #f0fff4 100%);
            }

            &[data-category="ai"] {
                background: linear-gradient(135deg, #ffffff 0%, #f0f4ff 100%);
            }
        }
    }

    // 响应式整
    @media (max-width: 768px) {
        .category-section {
            .category-header {
                h3.ant-typography {
                    font-size: 18px;
                    
                    .category-icon {
                        font-size: 22px;
                    }
                }
            }

            .tool-card {
                padding: 16px;
            }
        }
    }

    // 添加空状态样式
    .ant-empty {
        margin: 48px 0;

        .ant-empty-description {
            color: rgba(0, 0, 0, 0.45);

            span {
                background: rgba(0, 0, 0, 0.02);
                padding: 2px 8px;
                border-radius: 4px;
                margin: 0 4px;
            }
        }
    }
} 