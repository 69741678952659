@keyframes fadeInUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes scaleIn {
    from {
        opacity: 0;
        transform: scale(0.9);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

.about-page {
    max-width: 1200px;
    margin: 0 auto;
    padding: 24px;
    min-height: calc(100vh - 64px - 72px);

    .hero-section {
        text-align: center;
        margin-bottom: 64px;
        padding: 64px 0;
        background: linear-gradient(135deg, #f0f5ff 0%, #e6fffb 100%);
        border-radius: 16px;
        position: relative;
        overflow: hidden;
        animation: fadeInUp 0.8s ease-out;

        &::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            // background: url('/path-to-pattern.svg') center/cover;
            opacity: 0.1;
        }

        .hero-content {
            position: relative;
            z-index: 1;
            
            h1 {
                font-size: 48px;
                margin-bottom: 24px;
                background: linear-gradient(45deg, #1890ff, #36cfc9);
                -webkit-background-clip: text;
                color: transparent;
                font-weight: bold;
            }

            .intro {
                font-size: 20px;
                color: #666;
                max-width: 800px;
                margin: 0 auto 48px;
                line-height: 1.8;
            }

            .hero-stats {
                display: flex;
                justify-content: center;
                gap: 64px;

                .stat-item {
                    text-align: center;
                    animation: scaleIn 0.6s ease-out forwards;
                    opacity: 0;

                    @for $i from 1 through 3 {
                        &:nth-child(#{$i}) {
                            animation-delay: #{0.2 * $i}s;
                        }
                    }

                    .number {
                        display: block;
                        font-size: 36px;
                        font-weight: bold;
                        color: #1890ff;
                        margin-bottom: 8px;
                    }

                    .label {
                        color: #666;
                        font-size: 16px;
                    }
                }
            }
        }
    }

    .feature-cards {
        margin: 48px 0;

        .feature-card {
            height: 100%;
            border-radius: 12px;
            overflow: hidden;
            transition: all 0.3s ease;
            border: 1px solid #f0f0f0;
            animation: fadeInUp 0.6s ease-out forwards;
            opacity: 0;

            @for $i from 1 through 3 {
                &:nth-child(#{$i}) {
                    animation-delay: #{0.2 * $i}s;
                }
            }

            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
                border-color: transparent;

                .icon {
                    animation: bounce 0.5s ease;
                }
            }

            .card-content {
                padding: 32px;
                text-align: center;

                .icon {
                    font-size: 48px;
                    color: #1890ff;
                    margin-bottom: 24px;
                    transition: all 0.3s ease;
                }

                h4 {
                    font-size: 20px;
                    margin-bottom: 16px;
                    color: #1f1f1f;
                }

                .ant-typography {
                    color: #666;
                    line-height: 1.8;
                }

                .card-footer {
                    margin-top: 24px;
                }
            }
        }
    }

    .mission-section {
        margin: 64px 0;
        padding: 48px;
        background: linear-gradient(135deg, #f8f9fa 0%, #e6f7ff 100%);
        box-shadow: 0 4px 20px rgba(0, 0, 0, 0.05);
        border-radius: 16px;

        .mission-content {
            h2 {
                font-size: 36px;
                margin-bottom: 24px;
                color: #1f1f1f;
            }

            .mission-list {
                list-style: none;
                padding: 0;
                margin: 32px 0 0;

                li {
                    display: flex;
                    align-items: center;
                    margin-bottom: 16px;
                    font-size: 18px;
                    color: #666;
                    animation: fadeInUp 0.5s ease-out forwards;
                    opacity: 0;

                    @for $i from 1 through 3 {
                        &:nth-child(#{$i}) {
                            animation-delay: #{0.2 * $i}s;
                        }
                    }

                    .anticon {
                        font-size: 24px;
                        color: #1890ff;
                        margin-right: 16px;
                    }
                }
            }
        }
    }

    .tech-section {
        margin: 64px 0;

        .section-title {
            text-align: center;
            margin-bottom: 48px;
        }

        .tech-card {
            text-align: center;
            padding: 32px;
            border-radius: 12px;
            transition: all 0.3s ease;

            &:hover {
                transform: translateY(-5px);
                box-shadow: 0 8px 24px rgba(0, 0, 0, 0.12);
                .tech-icon {
                    transform: translateY(-5px);
                    color: #40a9ff;
                }
            }

            .tech-icon {
                font-size: 48px;
                color: #1890ff;
                margin-bottom: 24px;
            }
        }
    }

    .future-section {
        margin: 64px 0;

        .section-title {
            text-align: center;
            margin-bottom: 48px;
        }

        .timeline {
            position: relative;
            padding: 32px 0;

            &::before {
                content: '';
                position: absolute;
                left: 50%;
                top: 0;
                bottom: 0;
                width: 2px;
                background: linear-gradient(to bottom, transparent, #1890ff, transparent);
                transform: translateX(-50%);
                animation: scaleIn 0.3s ease-out forwards;
                animation-delay: 0.6s;
                opacity: 0;
            }

            .timeline-item {
                display: flex;
                align-items: center;
                margin-bottom: 48px;
                position: relative;
                animation: fadeInUp 0.6s ease-out forwards;
                opacity: 0;

                @for $i from 1 through 3 {
                    &:nth-child(#{$i}) {
                        animation-delay: #{0.3 * $i}s;
                    }
                }

                &:last-child {
                    margin-bottom: 0;
                }

                .time {
                    flex: 1;
                    text-align: right;
                    padding-right: 24px;
                    font-size: 18px;
                    font-weight: bold;
                    color: #1890ff;
                }

                .content {
                    flex: 1;
                    margin-left: 24px;
                    transition: all 0.3s ease;

                    &:hover {
                        transform: translateX(5px);
                    }
                }

                &::before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    width: 12px;
                    height: 12px;
                    background: #1890ff;
                    border-radius: 50%;
                    transform: translateX(-50%);
                    box-shadow: 0 0 0 4px rgba(24, 144, 255, 0.2);
                }
            }
        }
    }

    @media (max-width: 768px) {
        .hero-section {
            padding: 48px 24px;

            .hero-content {
                h1 {
                    font-size: 36px;
                }

                .intro {
                    font-size: 16px;
                }

                .hero-stats {
                    flex-wrap: wrap;
                    gap: 24px;

                    .stat-item {
                        flex: 1;
                        min-width: 120px;
                    }
                }
            }
        }

        .mission-section {
            padding: 32px 24px;
        }

        .timeline {
            &::before {
                left: 24px;
            }

            .timeline-item {
                flex-direction: column;
                align-items: flex-start;
                padding-left: 48px;

                .time {
                    padding-right: 0;
                    margin-bottom: 16px;
                }

                .content {
                    margin-left: 0;
                    width: 100%;
                }

                &::before {
                    left: 24px;
                }
            }
        }
    }
} 