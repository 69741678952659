.image-resizer {
    max-width: 900px;
    margin: 0 auto;
    padding: 24px;

    .ant-card {
        margin-bottom: 24px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0 4px 12px rgba(0, 0, 0, 0.12);
        }

        .ant-card-head {
            border-bottom: 1px solid #f0f0f0;
            padding: 0 24px;

            .ant-card-head-title {
                font-size: 16px;
                font-weight: 600;
                color: #1f1f1f;
            }
        }

        .ant-card-body {
            padding: 24px;
        }
    }

    .preview-area {
        background: #fafafa;
        border-radius: 8px;
        padding: 24px;
        text-align: center;
        min-height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        
        .image-container {
            display: flex;
            gap: 16px;
            justify-content: center;
            
            .image-wrapper {
                flex: 1;
                text-align: center;
                
                h4 {
                    margin-bottom: 8px;
                    color: #666;
                }
                
                .preview-image {
                    max-width: 100%;
                    height: auto;
                    border-radius: 4px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    cursor: zoom-in;

                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }

    .size-controls {
        display: flex;
        gap: 16px;
        margin-bottom: 16px;

        .ant-input-group {
            width: auto;
            flex: 1;

            .ant-input-group-addon {
                background: #fafafa;
                color: #666;
                border-color: #d9d9d9;
            }

            .ant-input {
                text-align: center;
                font-family: monospace;
            }
        }
    }

    .size-limits-info {
        background: #f8f8f8;
        border-radius: 6px;
        padding: 12px 16px;
        margin: 16px 0;
        border-left: 4px solid #1890ff;

        small {
            display: block;
            line-height: 1.6;
            color: #666;
        }

        .current-scale {
            margin-top: 8px;
            padding-top: 8px;
            border-top: 1px dashed #e8e8e8;
            color: #1890ff;
        }
    }

    .control-buttons {
        display: flex;
        gap: 8px;
        margin: 16px 0;

        .ant-btn {
            border-radius: 4px;
            
            &:hover {
                transform: translateY(-1px);
            }
        }
    }

    .quality-control {
        background: #fafafa;
        border-radius: 6px;
        padding: 16px;
        margin: 16px 0;

        .ant-slider {
            margin: 16px 8px 8px;
        }

        .quality-label {
            display: flex;
            justify-content: space-between;
            color: #666;
            
            .quality-value {
                color: #1890ff;
                font-weight: 500;
            }
        }
    }

    .mode-controls {
        display: flex;
        flex-wrap: wrap;
        gap: 24px;
        margin: 16px 0;

        .ant-radio-group {
            background: #fafafa;
            padding: 8px 16px;
            border-radius: 6px;
            border: 1px solid #f0f0f0;
        }
    }

    .download-button {
        margin-top: 24px;

        .ant-btn {
            height: 44px;
            font-size: 16px;
            border-radius: 6px;
            
            &:hover {
                transform: translateY(-1px);
                box-shadow: 0 4px 12px rgba(24, 144, 255, 0.25);
            }
        }
    }

    // 动画效果
    .preview-switch {
        .ant-radio-group {
            .ant-radio-button-wrapper {
                transition: all 0.3s ease;
                
                &:hover {
                    color: #1890ff;
                    border-color: #1890ff;
                }
            }
        }
    }

    // 响应式调整
    @media (max-width: 768px) {
        padding: 16px;

        .size-controls {
            flex-direction: column;
        }

        .mode-controls {
            gap: 16px;
        }

        .ant-card {
            margin-bottom: 16px;
            
            .ant-card-body {
                padding: 16px;
            }
        }
    }
}
