.image-compressor {
    max-width: 900px;
    margin: 0 auto;
    padding: 16px;

    .ant-card {
        margin-bottom: 12px;
        border-radius: 8px;
        box-shadow: 0 2px 8px rgba(0, 0, 0, 0.08);
    }

    .quality-control {
        background: #fafafa;
        border-radius: 6px;
        padding: 12px;
        margin: 12px 0;

        .quality-label {
            display: flex;
            justify-content: space-between;
            color: #666;
            
            .quality-value {
                color: #1890ff;
                font-weight: 500;
            }
        }

        .ant-slider {
            margin: 12px 8px 4px;
        }
    }

    .size-info {
        background: #f8f8f8;
        border-radius: 6px;
        padding: 12px;
        display: flex;
        flex-direction: column;
        gap: 8px;
    }

    .preview-area {
        .image-container {
            display: flex;
            gap: 16px;
            justify-content: center;
            
            .image-wrapper {
                flex: 1;
                text-align: center;
                
                h4 {
                    margin-bottom: 8px;
                    color: #666;
                }
                
                .preview-image {
                    max-width: 100%;
                    height: auto;
                    border-radius: 4px;
                    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
                    cursor: zoom-in;

                    img {
                        object-fit: contain;
                    }
                }
            }
        }
    }
} 