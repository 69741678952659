.header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1000;
    display: flex;
    align-items: center;
    padding: 0 24px;
    background-color: #f0f2f5;
    height: 64px;

    .logo {
        font-size: 20px;
        font-weight: bold;
        margin-right: 48px;
    }

    .ant-menu {
        background: transparent;
        border-bottom: none;
        flex: 1;
        
        .ant-menu-item {
            &:hover {
                background-color: rgba(0, 0, 0, 0.04);
            }
        }
    }

    .search {
        width: 240px;
        margin: 0 24px;
    }

    .ant-btn {
        &:not(:last-child) {
            margin-right: 8px;  // 控制登录注册按钮之间的间距
        }
    }
}