.app-footer {
    background: #f5f5f5;
    padding: 24px;
    text-align: center;
    
    .footer-content {
        max-width: 1200px;
        margin: 0 auto;

        .footer-links {
            margin-bottom: 16px;

            .ant-typography {
                margin: 0 12px;
                color: #666;

                &:hover {
                    color: #1890ff;
                }
            }
        }

        .copyright {
            color: #999;
        }
    }
}